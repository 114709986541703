import baseRequest from './baseReuqest';

export function uploadPhoto(file) {
    if (!file) return Promise.reject("Файл не выбран");

    const allowedTypes = ["image/jpeg"];

    // Проверяем MIME-тип
    if (!allowedTypes.includes(file.type)) {
        return Promise.reject("Ошибка! Можно загружать только файлы формата .jpg");
    }

    const data = new FormData();
    data.append('file', file);

    return baseRequest('/api/storage/file', {
        method: 'POST',
        body: data
    }, false);
}